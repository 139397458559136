import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HttpClient } from '@angular/common/http';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { PhoneMaskDirective } from './phone-mask.directive';
import { AddEditPageComponent } from '../pages/cms/add-edit-page/add-edit-page.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { LegalNoticeComponent } from '../pages/legal-notice/legal-notice.component';
import { TimePipe } from './time.pipe';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/', '.json');
}

const MODULES: any = [
  MatButtonModule,
  MatFormFieldModule,
  MatDialogModule,
  MatInputModule,
  MatSidenavModule,
  MatIconModule,
  MatCardModule,
  MatSelectModule,
  MatMenuModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatTooltipModule,

  FormsModule,
  ReactiveFormsModule,
  NgxMaterialTimepickerModule,

  TranslateModule.forChild({
     
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
    },
    defaultLanguage: 'fr',
    // useDefaultLang: true,  
})

];


@NgModule({
  declarations: [PhoneMaskDirective,AddEditPageComponent,PrivacyPolicyComponent,LegalNoticeComponent, TimePipe],
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...MODULES,
    TimePipe

  ]
})
export class SharedModule { }

import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpEventType, HttpClientModule, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

declare var Swal: any;

import { ApiConfiguration } from '../api-configuration';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService extends ApiConfiguration {
  langJson: any = {}
  token: any = {};
  uploadSub = new BehaviorSubject<any>(0);
  userID = sessionStorage.getItem('userID') ? sessionStorage.getItem('userID') : '';
  role = sessionStorage.getItem('role') ? sessionStorage.getItem('role') : 'Pharmacy';
  language = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
  pharmacyName = '';

  constructor(
    private http: HttpClient, 
    public translate: TranslateService, 
    public ToastrService: ToastrService,
    private dateAdapter: DateAdapter<Date>) {
    super();
    const token = sessionStorage.getItem('token');
    this.token = token ? token : null;
    // 
  }

  useLanguage(): void {
    this.translate.use(this.language).subscribe((res) => {
      this.langJson = res;
    });
    this.dateAdapter.setLocale(this.language);
  }

  setHeaderToken(token) {
    this.token = token;
    // sessionStorage.setItem('token', token);
  }

  getHeader() {
    return {
      headers: {
        Authorization: this.token
      }
    };
  }
  login(credentials) {
    return this.http.post(this.baseUrl + 'pharmacies/login', credentials);
  }

  public getData(subUrl: string, token = true,): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;

      this.http
        .get(request,
          token ? this.getHeader() : {})
        .subscribe(
          (data) => resolve(data),
          (error) => reject(error)
        );
    });
  }

  public postData(subUrl: string, data: any, token = true): Promise<any> {
    return new Promise((resolve, reject) => {

      const request: string = this.baseUrl + subUrl;
      this.http.post(request, data, token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => {

            reject(error);
          }
        );
    });
  }

  public putData(subUrl: string, data: any, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .put(request,
          data,
          token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => reject(error)
        );
    });
  }

  public deleteData(subUrl: string, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .delete(request,
          token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => reject(error)
        );
    });
  }

  public upload(file: any, url: any, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const uploadData = new FormData();
      uploadData.append('myFile', file, file.name);
      this.uploadSub.next(0);
      const request = this.http
        .post(url,
          uploadData,
          token ? { ...this.getHeader(), reportProgress: true, observe: 'events' } : { reportProgress: true, observe: 'events' })
        .subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            /*  */

            if (this.uploadSub.value !== null) {
              this.uploadSub.next((event['loaded'] / event['total']) * 100);
            } else {
              // Here Stop Uploading Request Manually
              request.unsubscribe();
            }
          } else if (event.type === HttpEventType.Response) {
            resolve(event.body);
          }
        },
          error => reject(error)
        );
    });
  }
  getProgress() {
    return this.uploadSub;
  }

  public getNotifications(obj) {
    return this.http.post(this.baseUrl + 'notifications/pagination', obj).pipe(catchError(this.handleError));
  }

  public deleteNotifications(id) {
    return this.http.delete(this.baseUrl + 'notifications/' + id).pipe(catchError(this.handleError));
  }

  public getPharmacies(data) {
    return this.http.post(this.baseUrl + 'pharmacies/parent/pagination', data).pipe(catchError(this.handleError));
  }
  public getLegal(key) {
    return this.http.get(this.baseUrl + 'legals/' + key).pipe(catchError(this.handleError));
  }

  public editLegal(key, data) {
      return this.http.put(this.baseUrl + 'legals/' + key, data).pipe(catchError(this.handleError));
  }

  public addLegal(data) {
    return this.http.post(this.baseUrl + 'legals/', data).pipe(catchError(this.handleError));
  }

  public deleleSinglePharma(id) {
    return this.http.delete(this.baseUrl + 'pharmacies/' + id).pipe(catchError(this.handleError));
  }
  public GetorderStatus(id) {
    return this.http.get(this.baseUrl + 'orders/'+id+'/status').pipe(catchError(this.handleError));
  }
  public deleleMultiplePharma(data) {
    const options = {
      headers: new HttpHeaders({
        'Authorization': sessionStorage.getItem('prtoken'),
      }),
      body: data
    };
    return this.http.delete(this.baseUrl + 'pharmacies/delete/multiple/', options).pipe(catchError(this.handleError));
  }

  public forgotPassword(data) {
    return this.http.post(this.baseUrl + 'pharmacies/sendResetLink', data).pipe(catchError(this.handleError));

  }
  public addPharmacies(data) {
    return this.http.post(this.baseUrl + 'pharmacies', data).pipe(catchError(this.handleError));

  }
  public getCode() {
    return this.http.get(this.baseUrl + 'pharmacies/generate/newid').pipe(catchError(this.handleError));

  }
  public getPromotions() {
    return this.http.get(this.baseUrl + 'promotions/pharmacy/' + this.userID + '/').pipe(catchError(this.handleError));
  }

  public getPromotionsbyId(id) {
    return this.http.get(this.baseUrl + 'promotions/' + id + '/').pipe(catchError(this.handleError));
  }

  public addPromotions(data) {
    return this.http.post(this.baseUrl + 'promotions/', data).pipe(catchError(this.handleError));
  }

  public editPromotions(id, data) {
    return this.http.put(this.baseUrl + 'promotions/' + id, data).pipe(catchError(this.handleError));
  }

  public delelePromotions(id) {
    return this.http.delete(this.baseUrl + 'promotions/' + id).pipe(catchError(this.handleError));
  }

  public getFaq() {
    return this.http.get(this.baseUrl + 'faqs/').pipe(catchError(this.handleError));
  }

  public getFaqbyId(id) {
    return this.http.get(this.baseUrl + 'faqs/' + id + '/').pipe(catchError(this.handleError));
  }

  public addFaq(data) {
    return this.http.post(this.baseUrl + 'faqs/', data).pipe(catchError(this.handleError));
  }

  public editFaq(id, data) {
    return this.http.put(this.baseUrl + 'faqs/' + id, data).pipe(catchError(this.handleError));
  }

  public deleleFaq(id) {
    return this.http.delete(this.baseUrl + 'faqs/' + id).pipe(catchError(this.handleError));
  }

  public getCMS() {
    return this.http.get(this.baseUrl + 'content_pages/all').pipe(catchError(this.handleError));
  }

  public getCMSbyId(id) {
    return this.http.get(this.baseUrl + 'content_pages/' + id + '/').pipe(catchError(this.handleError));
  }

  public addCMS(data) {
    return this.http.post(this.baseUrl + 'content_pages/', data).pipe(catchError(this.handleError));
  }

  public editCMS(id, data) {
    return this.http.put(this.baseUrl + 'content_pages/' + id, data).pipe(catchError(this.handleError));
  }


  public deleleCMS(id) {
    return this.http.delete(this.baseUrl + 'content_pages/' + id).pipe(catchError(this.handleError));
  }

  public getPharmacyOrders(data) {
    return this.http.post(`${this.baseUrl}orders/pagination`, data).pipe(catchError(this.handleError));
  }

  getOrderById(orderId) {
    return this.http.get(`${this.baseUrl}orders/${orderId}`);
  }

  public sendOrderMessage(orderId, data) {
    return this.http.put(this.baseUrl + 'orders/' + orderId + '/message', data).pipe(catchError(this.handleError));

  }
  getMessagesFcm() {
    return this.http.get(`${this.baseUrl}orders/${this.userID}/messages`).pipe(catchError(this.handleError));
  }

  // orders/:id/messages
  // orders/:id
  public sendOrderStatus(id, data) {
    return this.http.put(this.baseUrl + 'orders/' + id + '/status', data).pipe(catchError(this.handleError));

  }

  public resetPassword(data) {
    return this.http.post(this.baseUrl + 'pharmacies/change_pass', data).pipe(catchError(this.handleError));
  }

  public confirmOrder(data, orderId) {
    return this.http.put(this.baseUrl + 'orders/' + orderId + '/status', data).pipe(catchError(this.handleError));
  }

  public getAccountData() {
    return this.http.get(this.baseUrl + 'pharmacies/' + this.userID).pipe(catchError(this.handleError));
  }

  singlefile(data) {
    return this.http.post(this.baseUrl + 'upload/single', data).pipe(catchError(this.handleError));
  }
  // upload/multiple
  public multiplefile(data) {
    return this.http.post(this.baseUrl + 'upload/multiple', data).pipe(catchError(this.handleError));
  }
  public editaccountFileUpload(data) {
    return this.http.put(this.baseUrl + 'pharmacies/' + this.userID, data).pipe(catchError(this.handleError));
  }
  public editPharmacyUpload(id, data) {
    return this.http.put(this.baseUrl + 'pharmacies/' + id, data).pipe(catchError(this.handleError));
  }

  public newUser(email) {
    return this.http.get(this.baseUrl + 'pharmacies/is_new/' + email).pipe(catchError(this.handleError));
  }

  public isExist(email) {
    return this.http.get(this.baseUrl + 'pharmacies/exist/' + email).pipe(catchError(this.handleError));
  } 
  public phTAndC(key) {
    return this.http.get(this.baseUrl + 'legals/' + key).pipe(catchError(this.handleError));
  }
  public editTC(id, data) {
    return this.http.put(this.baseUrl + 'legals/' + id, data).pipe(catchError(this.handleError));
  }
  public addTC(data) {
    return this.http.post(this.baseUrl + 'legals/', data).pipe(catchError(this.handleError));
  }
  public sendNotification(id) {
    return this.http.get(this.baseUrl + "pharmacies/sendtimepush/" + id).pipe(catchError(this.handleError));
  }
  public CountNotification() {
    return this.http.get(this.baseUrl + "orders/phamarcyordercount/" + this.userID).pipe(catchError(this.handleError));
  }
  public sendDCNotification(id) {
    return this.http.get(this.baseUrl + "pharmacies/sendallnightpush/" + id).pipe(catchError(this.handleError));
  }

  printDiv(divId: ElementRef, id) {

    const css = `<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css"
    integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" crossorigin="anonymous">`;
    const printContents = divId.nativeElement.outerHTML;

    const pageContent = `<!DOCTYPE html><html><head>  <title>${id}</title>${css}</head><body onload="window.print();>${printContents}<style>body{padding:25px}</style></html>`;
    let popupWindow: Window;
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      popupWindow = window.open(
        '',
        '_blank',
        'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
      );
      popupWindow.window.focus();
      popupWindow.document.write(pageContent);
      popupWindow.document.close();
      popupWindow.print()
      if (popupWindow.onbeforeunload) {

        popupWindow.onbeforeunload = event => {
          popupWindow.close();
        };
      }
      if (popupWindow.onabort) {
        popupWindow.onabort = event => {
          popupWindow.document.close();
          popupWindow.close();
        };
      }

    } else {
      popupWindow = window.open('', '_blank', 'width=600,height=600');
      popupWindow.document.open();
      popupWindow.document.write(pageContent);
      popupWindow.document.close();
    }

  }

  public swalMessage(message, icon) {
    if (icon == 'success') {
      this.ToastrService.success(message)
    } else if (icon == 'error') {
      this.ToastrService.error(message)
    }
    // Swal.fire({
    //   title: message,
    //   icon: icon,
    //   confirmButtonText: 'ok'
    // })
  }
  itemsPerPageInfrench() {
    if (document.getElementsByClassName('mat-paginator-page-size-label')?.length) {
      if (sessionStorage.getItem('lang') == 'fr') {
        document.getElementsByClassName('mat-paginator-page-size-label')[0].innerHTML = 'Lignes par page';
      }
    }
  }

  nextAndPreviousInFr(){
    if(document.getElementsByClassName('mat-paginator-navigation-next')?.length){
      if (sessionStorage.getItem('lang') == 'fr') {
        let nxtTooltip: any = document.getElementsByClassName('mat-paginator-navigation-next');
        nxtTooltip[0].addEventListener("mouseover",(eve)=>{
          setTimeout(() =>{
            console.log(document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip'));
            if(document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip')){
              console.log(document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip'));

              document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip').innerHTML = 'Page suivante' 
            }
          },10)
        })
      }
    } 
    if(document.getElementsByClassName('mat-paginator-navigation-previous')?.length){
      if (sessionStorage.getItem('lang') == 'fr') {
        let nxtTooltip: any = document.getElementsByClassName('mat-paginator-navigation-previous');
        nxtTooltip[0].addEventListener("mouseover",(eve)=>{
          setTimeout(() =>{
            if(document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip')){
              document.querySelector('.cdk-overlay-container .mat-tooltip-panel .mat-tooltip').innerHTML = 'Page précédente' 
            }
            // for(let i = 0; i <7; i++){
            //   console.log(document.querySelector(`#cdk-overlay-${i}`));
            //   if(document.querySelector(`#cdk-overlay-${i} .mat-tooltip`)){
            //     console.log('kjafkjdahfkj');
            //     document.querySelector(`#cdk-overlay-${i} .mat-tooltip`).innerHTML = 'Page précédente' 
            //   }
            // }
          },10)
        })
      }
    }
  }

  private handleError(errorResp: HttpErrorResponse) {
    console.log('errorResp: ', errorResp);
    let errorMessage = 'Something went wrong!';
    if (errorResp.error && errorResp.error.message) {
      errorMessage = errorResp.error.message;
    }
    return throwError(errorMessage);
  }
}

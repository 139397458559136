import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPageComponent } from '../cms/add-edit-page/add-edit-page.component';
import { ApiCallService } from '../../services/api/api-call.service';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  data: any
  constructor(
    public dialog: MatDialog, public ApiCallService: ApiCallService
  ) { }

  ngOnInit(): void {
 this.showdata()

  }
showdata(){
  this.ApiCallService.getLegal('phPrivacy').subscribe((data:any) => {
    
    if(data?.status == '200'){
      this.data = data?.data
    }else{
      this.ApiCallService.swalMessage(data.message,'error')
    }
  },(err)=>{
    this.ApiCallService.swalMessage(err.message,'error')
  })
}
  onEdit() {
    const dialogRef = this.dialog.open(AddEditPageComponent, {
      width: '70%',
      autoFocus: false,
      restoreFocus: false,
      data:{'title':"phPrivacy"}
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if(result){
        this.showdata()
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditTandcComponent } from './add-edit-tandc/add-edit-tandc.component';
import { ApiCallService } from '../../services/api/api-call.service';

@Component({
  selector: 'app-tandc',
  templateUrl: './tandc.component.html',
  styleUrls: ['./tandc.component.scss']
})
export class TandcComponent implements OnInit {

  constructor(public ApiCallService: ApiCallService, public dialog: MatDialog) { }
  public utilityObject :any = {
    tC_data:{}
  }

  ngOnInit(): void {
    this.showdata();
  }

  showdata() {
    this.ApiCallService.phTAndC('phTAndC').subscribe((data: any) => {
      if (data?.status == '200') {
        // console.log('data1 : ', data.data)
        this.utilityObject.tC_data = data?.data ?? {};
      } else {
        console.log('data2 : ', data)
        this.ApiCallService.swalMessage(data.message, 'error')
      }
    }, (err) => {
      this.ApiCallService.swalMessage(err.message, 'error')
    })
  }

  onEdit(data?) {
    // console.warn("edit data : " ,data);
    const dialogRef = this.dialog.open(AddEditTandcComponent, {
      width: '70%',
      autoFocus: false,
      restoreFocus: false,
      data: data.tC_data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showdata();
      }
    });
  }

}

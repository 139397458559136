import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPageComponent } from '../cms/add-edit-page/add-edit-page.component';
import { ApiCallService } from '../../services/api/api-call.service';


@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {
  data: any
  constructor(
    public dialog: MatDialog, public ApiCallService: ApiCallService
  ) { }

  ngOnInit(): void {
    this.showdata();
   
     }
   showdata(){
     this.ApiCallService.getLegal('phLegal').subscribe((data:any) => {
       
       if(data?.status == '200'){
         this.data = data?.data
       }else{
         this.ApiCallService.swalMessage(data.message,'error')
       }
     },(err)=>{
       this.ApiCallService.swalMessage(err.message,'error')
     })
   }
  onEdit() {
    const dialogRef = this.dialog.open(AddEditPageComponent, {
      width: '70%',
      autoFocus: false,
      restoreFocus: false,
      data:{'title':"legal"}
    });

    dialogRef.afterClosed().subscribe(result => {
      
    this.showdata();
    });
  }
}

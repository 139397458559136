import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ApiCallService } from '../../services/api/api-call.service';


@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  ConfirmPopupComponentUtil: any = {
    showHeading: true,
    orderData:{}
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public ApiCallService: ApiCallService, private dialogRef: MatDialogRef<ConfirmPopupComponent>) {
  }

  ngOnInit(): void {
    
    if (this.data.status == 'cancelled') {
      this.ConfirmPopupComponentUtil.showHeading = false
    }
  }
  confirmOrder() {
    if (this.data.status == 'confirm') {
       this.ConfirmPopupComponentUtil.orderData = {
        "user": this.data?.orderDetails?.user?._id,
        "pharmacy": this.data?.orderDetails?.pharmacy,
        "status": 'In Preparation',
        "number": this.data?.orderDetails?.number
      }
     
    } else {
      this.ConfirmPopupComponentUtil.orderData = {
        "user": this.data?.orderDetails?.user?._id,
        "pharmacy": this.data?.orderDetails?.pharmacy,
        "status": 'Cancelled',
        "number": this.data?.orderDetails?.number
      }
    }
    this.ApiCallService.confirmOrder(this.ConfirmPopupComponentUtil.orderData, this.data?.orderDetails?._id).subscribe((data: any) => {
      if (data?.status == '200') {
        this.ApiCallService.swalMessage(data.message, 'success');
        this.dialogRef.close(true);
      } else {
        this.ApiCallService.swalMessage(data.message, 'error');
        this.dialogRef.close(false);
      }
    }, (err) => {
      this.ApiCallService.swalMessage(err.message, 'error');
      this.dialogRef.close(false);
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiCallService } from '../../services/api/api-call.service';
import { Router } from '@angular/router';
declare var Swal: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  spinner: boolean = false;
  isSubmit: boolean = false;
  isNewUser: boolean = true;
  loginUtil: any = {
    email: ''
  }
  error = {
    email: false,
    password: false
  };
  constructor(
    public ApiCallService: ApiCallService, public router: Router
  ) {
    localStorage.setItem('lang', 'fr')
    this.ApiCallService.useLanguage()
   }

  ngOnInit(): void {
  }

  toforgotPassword(){
    this.router.navigate(['/forgot-password'], {state:{fromLogin: true}})
  }

  isUserRegistered(email){
    this.loginUtil.email = email.trim();
    this.ApiCallService.newUser(this.loginUtil.email).subscribe((user: any) => {
      
      if(user.status == 200){
        this.isNewUser= user.data
      }
    })
  }

  onLogin(form) {
    this.isSubmit = true;
    this.spinner = true;
    if (form?.valid) {
      this.ApiCallService.login({
        'email': form.value.email,
        'password': form.value.Password,
        'label': form.value.language
      }).subscribe((data: any) => {
        if (data?.status == '200') {
          this.ApiCallService.pharmacyName = data?.data?.name ? data?.data?.name : "Pharma Saify";
          sessionStorage.setItem('userID', data?.data?._id)
          sessionStorage.setItem('role', data?.data?.role)
          sessionStorage.setItem('prtoken', data?.token)
          sessionStorage.setItem('lang', data?.data?.label??'en')
          this.ApiCallService.language = data?.data?.label??'en';
          this.ApiCallService.role = data?.data?.role??'Pharmacy'
          this.ApiCallService.swalMessage(data?.message, 'success');
          setTimeout(() => {
            this.spinner = false;
            this.ApiCallService.userID = sessionStorage.getItem('userID')
            this.router.navigate(['/orders'])
          }, 1500)
        } else {
          this.spinner = false;
          this.ApiCallService.swalMessage(data?.message, 'error');
        }
      }, (err) => {
        this.spinner = false;
        this.ApiCallService.swalMessage(err?.message, 'error');
      })
    }
  }

}

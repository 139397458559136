import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ApiCallService} from '../services/api/api-call.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router, public ApiCallService:ApiCallService
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;
    const user = sessionStorage.getItem('userID')? sessionStorage.getItem('userID'): '';
    if (user.length > 0) {
      return true;
    }else{
      sessionStorage.removeItem('userID');
      sessionStorage.removeItem('role');
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}




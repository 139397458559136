import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ApiCallService } from './api/api-call.service';
import { Location } from '@angular/common';



@Injectable()
export class FirebaseService {
  NewNotification: boolean = false
  public notificationCount = 0;
  currentMessage = new BehaviorSubject(null);
  token;
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    public ApiCallService: ApiCallService,
    public location: Location) {
    this.angularFireMessaging.messages.subscribe((msg: any) => {
      // console.log("MSG :", msg);
      msg.onMessage = msg.onMessage.bind(msg);
      msg.onTokenRefresh = msg.onTokenRefresh.bind(msg);
    });
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        let data = {
          "fcmToken": token
        }
        console.log('token :', data);
        this.updateToken(this.ApiCallService.userID, token);
        this.ApiCallService.editaccountFileUpload(data).subscribe((data: any) => {
          // console.log('data: ', data);
        })
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  getNotificationsObs() {
    return this.currentMessage.asObservable();
  }

  /**
   * hook method when new notification received in foreground
   */
  playAudio() {
    let audio = new Audio();
    audio.src = "../../assets/mixkit-positive-notification-951.wav";
    audio.load();
    audio.play();
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log("new message received. ", payload);
      this.ApiCallService.getMessagesFcm().subscribe((data: any) => {
        console.log('data: ', data);
      })
      this.playAudio();
      this.notificationCount = this.notificationCount + 1;
      this.currentMessage.next(payload);
    });
  }
}
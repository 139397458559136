import { Directive, ElementRef, HostListener, Input, Renderer2 } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appPhoneMaskDirective]"
})
export class PhoneMaskDirective {
  @Input('appPhoneMask') public appPhoneMask: any = {};
  constructor(public ngControl: NgControl, public element: ElementRef, public renderer: Renderer2) {}

  @HostListener("ngModelChange", ["$event"])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener("keydown.backspace", ["$event"])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    console.log('event: ', event);
    let newVal = event.toString().replace(/\D/g, "");
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, "($1)");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    }
    this.element.nativeElement.value = newVal
    console.log('newVal: ', newVal);
    }
}

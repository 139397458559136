import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from '../../../services/api/api-call.service';
declare var $: any;

@Component({
  selector: 'app-add-edit-tandc',
  templateUrl: './add-edit-tandc.component.html',
  styleUrls: ['./add-edit-tandc.component.scss']
})
export class AddEditTandcComponent implements OnInit {

  AddEditTCComponent: any = {
    editData: { 
      title: '',
      description: '' 
    },
    isEdit: false
  }
  titleLength: any;
  descriptionLength: any;
  constructor(
    public dialogRef: MatDialogRef<AddEditTandcComponent>, public ApiCallService: ApiCallService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  
  
  ngOnInit(): void {
    // console.warn("TC data: ", this.data)
    if (this.data) {
      this.AddEditTCComponent.isEdit = true;
      this.AddEditTCComponent.editData = this.data;
      this.titleLength = this.data.title.length;
      this.descriptionLength = this.data.description.length;
      this.initSummerNote();
    }
  }

  initSummerNote() {
    $('#TCdesc').summernote({
      height: 180,
      toolbar: [
        // [groupName, [list of button]],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['table', ['table']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['link', ['linkDialogShow', 'unlink']]
      ],
    });
    // $('#TCdesc').text = "Hello World <strong>from Slovakia</strong>!";
    let data: any = document.getElementsByClassName('note-editable')
    data[0].children[0].innerHTML = this.data?.description
  }

  onSave(title, editor) {
    let val = $('#cms').val();
    let description =  this.removeHTML(editor?.value)
    if (this.data) {
      this.ApiCallService.editTC(this.data._id, { 'title': title.value, 'description': editor?.value }).subscribe((data: any) => {
        if (data?.status == '200') {
          this.ApiCallService.swalMessage(data.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.ApiCallService.swalMessage(data.message, 'error');
          this.dialogRef.close(false);
        }
      }, (err) => {
        this.ApiCallService.swalMessage(err.message, 'error');
        this.dialogRef.close(false);
      })
    } else {
      this.ApiCallService.addTC({ 'title': title.value, 'description': editor?.value }).subscribe((data: any) => {
        if (data?.status == '200') {
          this.ApiCallService.swalMessage(data.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.ApiCallService.swalMessage(data.message, 'error');
          this.dialogRef.close(false);

        }
      }, (err) => {
        this.ApiCallService.swalMessage(err.message, 'error');
        this.dialogRef.close(false);
      })
    }
  }

  removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  onClose() {
    $('#cms').summernote('destroy');
    this.dialogRef.close();
  }

  onSearchChange(searchValue: string): void {
    console.log(searchValue);
  }

}

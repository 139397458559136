import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class Httpinterceptor implements HttpInterceptor {
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = {
            'Authorization': this.getToken(),
            // 'Content-Type': 'application/json',
            };
        return next.handle(httpRequest.clone({ setHeaders:   headers} ));
     
    }
    getToken():string{
        const sCLToken = sessionStorage.getItem('prtoken') ? 'Bearer '+sessionStorage.getItem('prtoken') :''
         return sCLToken
       }
  }
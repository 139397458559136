import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallService } from '../../services/api/api-call.service';
declare var Swal: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetUtility: any = {
    newPassword: '',
    confirmpassword: '',
    spinner: false,
    ipType:'text',
    ipType1:'text'
  }
  constructor(public ApiCallService: ApiCallService, public router: Router) {
    const localdata = this.router.getCurrentNavigation();
    console.log('localdata: ', localdata);
    if(localdata?.extras?.state?.hasOwnProperty('fromAccount')){
      sessionStorage.setItem('lang', this.ApiCallService.language)

    }else{
      this.router.navigate(['/login'])
    }
   }

  ngOnInit(): void {
  }
  resetPassword(resetForm: any) {

    if (resetForm?.valid && this.resetUtility?.newPassword == this.resetUtility?.confirmpassword) {
      let obj = {
        "password": this.resetUtility?.newPassword,
        "pharmacyId": this.ApiCallService?.userID
    }
    this.resetUtility.spinner = true;
      this.ApiCallService.resetPassword(obj).subscribe((resp: any) => {
        if (resp?.status == 200) {
          sessionStorage.removeItem('userID');
          sessionStorage.removeItem('role');
          this.ApiCallService.swalMessage(resp?.message, 'success')
          setTimeout(() => {
            this.resetUtility.spinner = false;
            this.router.navigate(['login']);
          }, 3000)
        } else {
          this.resetUtility.spinner = false;
          this.ApiCallService.swalMessage(resp?.message, 'error');
        }
      }, (err) => {
        this.resetUtility.spinner = false;
        this.ApiCallService.swalMessage(err?.message, 'error');
      })
    }
  }
  changeInputfocus(event){
    event.type = 'text';
   
  }

changeInput(event){
  event.type ='password';  
}
}


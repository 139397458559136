import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    moment.locale(sessionStorage.getItem('lang'))
    return moment(value).format('dddd DD MMM-kk : mm') ;
  }

}

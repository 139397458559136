import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-add-edit-page',
  templateUrl: './add-edit-page.component.html',
  styleUrls: ['./add-edit-page.component.scss']
})
export class AddEditPageComponent implements OnInit {
  AddEditPageComponentUtil: any = {
    editData: { title: '', description: '' },
    isEdit: false,
    headding: 'Add_a_Page',
    duplicateTitle : false
  }
  constructor(
    public dialogRef: MatDialogRef<AddEditPageComponent>, public ApiCallService: ApiCallService,
    @Inject(MAT_DIALOG_DATA) public data: any, public location: Location
  ) { }

  ngOnInit(): void {

    if (this?.data?.title) {
      
      this.AddEditPageComponentUtil.isEdit = true;
      this.onEdit(this?.data)
      if(this?.data?.title  == 'legal' || this?.data?.title == 'privacy'){
        this.AddEditPageComponentUtil.editData.title = this.data.title;
      }
    } else {
      this.initSummerNote();
    }
    if (this.location.path() == '/privacy-policy') {
      this.AddEditPageComponentUtil.headding = 'Privacy_Policy'
    } else if (this.location.path() == '/legal-notice') {
      this.AddEditPageComponentUtil.headding = 'Legal_Notice'

    }
    console.log('this.AddEditPageComponent',this.data);
  }

  onEdit(data) {
    let localKey = ''
    if ((this.location.path() == '/privacy-policy' || this.location.path() == '/legal-notice') || (this.location.path() == '/cms' && data.hasOwnProperty('key'))) {
      if (this.location.path() == '/privacy-policy') {
        localKey = 'phPrivacy';
      } else if (this.location.path() == '/legal-notice') {
        localKey = 'phLegal';
      }else if (this.location.path() == '/cms' && data.hasOwnProperty('key')){
        localKey = data?.key??'';
      }
      this.ApiCallService.getLegal(localKey).subscribe((data: any) => {
        if (data?.status == '200') {
          this.AddEditPageComponentUtil.editData = data?.data;
          this.initSummerNote();
        } else {
          this.ApiCallService.swalMessage(data.message, 'error')
        }
      }, (err) => {
        this.ApiCallService.swalMessage(err.message, 'error')
      })
    }
    if (data?._id && this.location.path() == '/cms' && !data.hasOwnProperty('key')) {
      this.ApiCallService.getCMSbyId(data._id).subscribe((data: any) => {
        if (data?.status == '200') {
          this.AddEditPageComponentUtil.editData = data?.data;
          this.initSummerNote();
        }
      })
    }
  }

  initSummerNote() {
    $('#cms').summernote({
      height: 180,
      toolbar: [
        // [groupName, [list of button]]
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['table', ['table']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['link', ['linkDialogShow', 'unlink']]
      ],
    });
    $('#cms').text = "Hello World <strong>from Slovakia</strong>!";
    let data: any = document.getElementsByClassName('note-editable')
    data[0].children[0].innerHTML = this.AddEditPageComponentUtil?.editData?.description ?? ''
  }

  titleCheck(){
    let checkduptitle =  this.data.totalTitles.filter( (title) => title == this.AddEditPageComponentUtil.editData.title);
    if(checkduptitle.length> 0){
     this.AddEditPageComponentUtil.duplicateTitle = true; 
    }else{
     this.AddEditPageComponentUtil.duplicateTitle = false; 

    }
  }

  onSave(title, editor) {
    let val = $('#cms').val();
    let localKey = ''
    if ((this.location.path() == '/privacy-policy' || this.location.path() == '/legal-notice') || (this.location.path() == '/cms' && this?.data?.hasOwnProperty('key'))) {
      if (this.location.path() == '/privacy-policy') {
        localKey = 'phPrivacy'
      } else if (this.location.path() == '/legal-notice') {
        localKey = 'phLegal'
      }if (this.location.path() == '/cms' && this?.data.hasOwnProperty('key')){
        localKey = this?.data?.key??'';
      }
      let obj = {
        "title": title?.value ?title?.value  :  this.data?.title??'' ,
        "description": editor?.value,
        "key": localKey?localKey: this.data?.key ? this.data?.key :  this.data?.title ? this.data?.title:''
      }

      this.ApiCallService.editLegal(this.AddEditPageComponentUtil?.editData?._id, obj).subscribe((data: any) => {
        if (data?.status == '200') {
          this.ApiCallService.swalMessage(data.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.ApiCallService.swalMessage(data.message, 'error');
          this.dialogRef.close(false);
        }
      }, (err) => {
        this.ApiCallService.swalMessage(err.message, 'error');
        this.dialogRef.close(false);
      })
    }

    // let description =  this.removeHTML(editor?.value)
    if (this.location.path() == '/cms' && this?.data?.title && !this?.data?.hasOwnProperty('key')) {
      let localtitles = [...this?.data?.totalTitles]
      if(localtitles.indexOf( title.value)!= -1){
        localtitles.splice(localtitles.indexOf( title.value), 1)
      }
      let callApi = localtitles?.filter(val => val == title.value);
      if(callApi.length == 0) {
      this.ApiCallService.editCMS(this?.data?._id, { 'title': title?.value, 'description': editor?.value }).subscribe((data: any) => {
        if (data?.status == '200') {
          this.ApiCallService.swalMessage(data.message, 'success');
          this.dialogRef.close(true);
        } else {
          this.ApiCallService.swalMessage(data.message, 'error');
          this.dialogRef.close(false);
        }
      }, (err) => {
        this.ApiCallService.swalMessage(err.message, 'error');
        this.dialogRef.close(false);
      })
    }
    } else if (this.location.path() == '/cms' && !this?.data?.hasOwnProperty('key')) {
      let callApi = this.data.totalTitles.filter(val => val == title.value);
      if(callApi.length == 0) {
        this.ApiCallService.addCMS({ 'title': title.value, 'description': editor?.value }).subscribe((data: any) => {
          if (data?.status == '200') {
            this.ApiCallService.swalMessage(data.message, 'success');
            this.dialogRef.close(true);
          } else {
            this.ApiCallService.swalMessage(data.message, 'error');
            this.dialogRef.close(false);
  
          }
        }, (err) => {
          this.ApiCallService.swalMessage(err.message, 'error');
          this.dialogRef.close(false);
        })
      }
    }
  }

  removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  onClose() {
    $('#cms').summernote('destroy');
    this.dialogRef.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ApiCallService} from '../../services/api/api-call.service';

declare var Swal: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  ForgotPasswordComponentUtil: any ={
    spinner: false
  }
  constructor(
    public ApiCallService:ApiCallService, private router: Router
  ) { 
    const localdata = this.router.getCurrentNavigation();
    if(localdata?.extras?.state?.hasOwnProperty('fromLogin')){
      sessionStorage.setItem('lang', 'fr')
      this.ApiCallService.useLanguage()

    }else{
      this.router.navigate(['/login'])
    }
  }

  ngOnInit(): void {
  
  }

  forgotPassword(email){
    
    this.ApiCallService.forgotPassword({'email':email.value.email}).subscribe((data:any) => {
      this.ForgotPasswordComponentUtil.spinner = true;
      if(data?.status == '200'){
        
        this.ApiCallService.swalMessage(data.message,'success');

        this.ForgotPasswordComponentUtil.spinner = false;
        setTimeout(() => {
          sessionStorage.clear();
          this.router.navigate(['/login'])
        },2000)

      }else{
        this.ApiCallService.swalMessage(data.message,'error');
        this.ForgotPasswordComponentUtil.spinner = false;

      }
    },(err)=>{
      this.ApiCallService.swalMessage(err.message,'error');
      this.ForgotPasswordComponentUtil.spinner = false;

    })
  }
}


import { environment } from "../../environments/environment";

export class ApiConfiguration {
    readonly baseUrl: string = environment.apiUrl;
    readonly orderUrl = 'orders/';
    readonly pharmacyUrl = 'pharmacies/';
    readonly promotionUrl = 'promotions/';
    readonly faqsUrl = 'faqs/';
    readonly labesUrl = 'labes/';
    readonly usersUrl = 'users/';
    readonly content_pagesUrl = 'content_pages/';
    readonly uploadUrl = 'upload/';
    readonly firebaseUrl = 'firebase/';
}